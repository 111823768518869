<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Stagiaires
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiAccountMultiple }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-card-text class="d-flex justify-space-between">
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Rechercher"
          single-line
          hide-details
          @change="searchStud"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-3 mt-4 ml-10"
          right
          top
          @click="$router.push({name:'contact-new'})"
        >
          Nouveau stagiaire
        </v-btn>
      </v-card-text>
      <v-divider>
      </v-divider>

      <v-data-table
        :calculate-widths="true"
        :disable-pagination="true"
        :header-props="{ sortByText: 'Trier par' }"
        :loading="loading"
        loading-text="Récupération des apprenants..."
        no-data-text="Saisir au moins trois caractères"
        no-results-text="Pas de résultats trouvé :("
        :headers="headers"
        :items="contacts"
        item-key="id"
        class="table-rounded"
        @click:row="goToStud"
      >
        <!-- DATE DE CONTACT -->
        <template
          #[`item.contactPoints`]="{item}"
        >
          <div
            v-if="item.contactPoints.length > 0"
            class="d-flex flex-column"
          >
            {{ item.contactPoints.contactDate | date('dd/MM/yyyy à HH:mm') }}
          </div>
          <div
            v-else
            class="d-flex flex-column"
          >
            Pas encore contacté
          </div>
        </template>

        <!-- STATUS -->
        <template #[`item.status`]="{item}">
          <div class="d-flex flex-column text-center">
            <v-chip
              class="justify-center font-weight-medium text-center"
              color="primary"
              small
            >
              STATUS MON CUL {{ item.status }}
            </v-chip>
          </div>
        </template>
        <!-- DATE DE RAPPEL -->
        <template #[`item.rappel`]="{item}">
          <div
            v-if="item.rappel"
            class="d-flex flex-column"
          >
            {{ new Date(item.rappel) | date('dd/MM/yyyy à HH:mm') }}
          </div>
        </template>
        <!-- COURSE -->
        <template
          #[`item.courses`]="{item}"
        >
          <div
            v-if="item.courses"
            class="d-flex flex-column text-center justify-center"
          >
            <v-chip-group column>
              <CourseStatusChip
                :course="item.courses[0]"
              />
              <v-chip
                v-if="item.courses.length > 0"
              >
                + {{ item.courses.length -1 }} autre{{ (item.courses.length -1) > 1 ? 's' : '' }}
              </v-chip>
            </v-chip-group>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import CourseStatusChip from '@/components/CourseStatusChip.vue'
import {
  mdiSquareEditOutline, mdiDotsVertical, mdiMagnify, mdiAccountMultiple,
} from '@mdi/js'

export default {
  components: {
    CourseStatusChip,
  },
  data() {
    return {
      chipColor: 'warning',
      contacts: [],
      search: '',
      items: {},
      noresults: true,
      headers: [
        { text: 'Nom', value: 'lastName' },
        { text: 'Prenom', value: 'firstName' },
        { text: 'Formations', value: 'courses' },
        { text: 'Rappel', value: 'rappel' },
      ],
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiMagnify,
        mdiAccountMultiple,
      },

    }
  },
  computed: {
    loading() {
      return this.search.length > 2 && this.contacts.length === 0 && !this.noresults
    },
  },
  watch: {
    search(e) {
      this.searchStud(e)
    },
  },

  created() {
    this.getLatestContacts()
  },
  methods: {
    getLatestContacts() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/contact/all?with=courses.course`)
        .then(response => {
          this.contacts = response.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchStud(e) {
      if (e.length > 2) {
        this.stud = []
        this.$http.get(`${process.env.VUE_APP_API_URL}/contact/search?query=${e}`)
          .then(response => {
            this.noresults = response.data.length === 0
            this.contacts = response.data
          },
          error => {
            console.log(error)
          })
      }
    },
    goToStud({ _id }) {
      this.$router.push({ name: 'contact-single', params: { id: _id } })
    },
  },

}
</script>
