<template>
  <div>
    <v-chip :color="getColor()">
      {{ course.course.name }} - {{ getStatusText() }}
    </v-chip>
  </div>
</template>

<script>

export default {
  props: {
    course: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      colors: {
        waiting: 'waiting',
        ongoing: 'step1',
        afterCourse: 'step2',
        registeredCert: 'step3',
        certificated: 'step4',
        other: 'info',
      },
      texts: {
        waiting: 'En attente',
        ongoing: 'En cours',
        afterCourse: 'Formation finie',
        registeredCert: 'Inscrit certification',
        certificated: 'Certification passée',
        other: 'Autre',
      },
    }
  },
  methods: {
    getColor() {
      return this.colors[this.course.status]
    },
    getStatusText() {
      return this.texts[this.course.status]
    },
  },

}
</script>

<style>

</style>
