<template>
  <div>
    <v-row
      class="mb-5"
    >
      <v-col
        sm="6"
        md="3"
        cols="12"
      >
        <v-skeleton-loader
          v-if="loading"
          type="card-heading"
        ></v-skeleton-loader>
        <v-card v-else>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ nbContacts }}
              </h2>
              <span>apprenant(s)</span>
            </div>
            <v-avatar>
              <v-icon
                color="primary"
              >
                {{ icons.mdiAccount }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        sm="6"
        md="3"
        cols="12"
      >
        <v-skeleton-loader
          v-if="loading"
          type="card-heading"
        ></v-skeleton-loader>
        <v-card v-else>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ nbBCs }}
              </h2>
              <span>Entreprises</span>
            </div>
            <v-avatar>
              <v-icon
                color="primary"
              >
                {{ icons.mdiBriefcase }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        sm="6"
        md="3"
        cols="12"
      >
        <v-skeleton-loader
          v-if="loading"
          type="card-heading"
        ></v-skeleton-loader>
        <v-card v-else>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ nbStudentsInscrits }}
              </h2>
              <span>Inscrits à la certification</span>
            </div>
            <v-avatar>
              <v-icon
                color="primary"
              >
                {{ icons.mdiSchool }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        sm="6"
        md="3"
        cols="12"
      >
        <v-skeleton-loader
          v-if="loading"
          type="card-heading"
        ></v-skeleton-loader>
        <v-card v-else>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ nbStudentsSortis }}
              </h2>
              <span>Sortis de formation</span>
            </div>
            <v-avatar>
              <v-icon
                color="primary"
              >
                {{ icons.mdiAccountArrowRight }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ContactsDatatable></ContactsDatatable>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccount, mdiSchool, mdiAccountArrowRight, mdiBriefcase } from '@mdi/js'

import ContactsDatatable from '@/views/pages/Contacts/ContactsDatatable.vue'

export default {
  components: {
    ContactsDatatable,
  },
  data() {
    return {
      contacts: [],
      loading: true,
      nbContacts: 0,
      nbBCs: 0,
      nbStudentsSortis: 0,
      nbStudentsInscrits: 0,
      icons: {
        mdiAccount,
        mdiSchool,
        mdiAccountArrowRight,
        mdiBriefcase,
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.countContacts(), this.countBCs(), this.countStudentsSortis(), this.countStudentsInscrits()])
        .then(values => {
          [this.nbContacts, this.nbBCs, this.nbStudentsSortis, this.nbStudentsInscrits] = values
        })
        .catch(err => {
          console.log((err))
        })
        .finally(this.loading = false)
    },
    async countContacts() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/contact/count`)

      return response.data
    },
    async countBCs() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/business-contact/count`)

      return response.data
    },
    async countStudentsSortis() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/contact/count?status=sortieFormation&inclusive=true`)

      return response.data
    },
    async countStudentsInscrits() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/contact/count?status=inscritCertif&inclusive=true`)

      return response.data
    },
  },
}
</script>
